<template>
  <svg :height="height" :width="width" :style="{aspectRatio: ratio}" viewBox="0 0 257.297 67.493" xmlns="http://www.w3.org/2000/svg">
    <g transform="translate(-94.608 -55.439)">
      <path
        d="M153.987,64.249l-.055-1.275H120.546l-.032,1.389c.555,18.283,24.749,23,32.194,6.3l.456-1.3H150.5l-.484,1.133a14.229,14.229,0,0,1-26.771-3.774l-.169-1.22h30.86Z"
        fill="#0e4293" transform="translate(76.04 22.117)"/>
      <path
        d="M123.868,69.536a14.226,14.226,0,0,1,25.551,0l.48,1.106h2.672l-.484-1.3a16.732,16.732,0,0,0-30.888,0l-.484,1.3h2.668Z"
        fill="#0e4293" transform="translate(76.633 10.584)"/>
      <path
        d="M154,75.79l.055-1.247c2.145-17.244,26.189-17.244,28.334,0l.059,1.247V92.511h2.526V75.79L184.92,74.4a16.759,16.759,0,0,0-15.3-15.3l-1.393-.055-1.389.055a16.763,16.763,0,0,0-15.3,15.3l-.055,1.389V92.511H154Z"
        fill="#5a5c5e" transform="translate(166.926 10.576)"/>
      <rect fill="#5a5c5e" height="33.473" transform="translate(272.949 69.614)" width="2.53"/>
      <path
        d="M142.455,57.256l-.055-.4-.173-.342a1.289,1.289,0,0,0-2.243.342l-.055.4.055.4a1.275,1.275,0,0,0,2.243.338l.173-.338Z"
        fill="#5a5c5e" transform="translate(133.024 1.658)"/>
      <path
        d="M133.578,69.536a14.224,14.224,0,0,1,25.551,0l.48,1.106h2.672l-.484-1.3a16.732,16.732,0,0,0-30.888,0l-.484,1.3H133.1Z"
        fill="#ff4419" transform="translate(105.134 10.584)"/>
      <path
        d="M163.66,62.974H130.273l-.055,1.275.055,1.251h30.86l-.169,1.22A14.228,14.228,0,0,1,134.193,70.5l-.484-1.137H131.04l.453,1.306a16.762,16.762,0,0,0,32.222-6.3Z"
        fill="#ff4419" transform="translate(104.523 22.117)"/>
      <path
        d="M141.692,76.985l-.169,1.247c-3.4,16.288-26.2,15.281-28.137-1.247l-.055-1.224.055-1.247.142-1.22A14.23,14.23,0,0,1,126.3,61.6l1.22-.059,1.247.059a14.242,14.242,0,0,1,12.494,10.476l.256,1.22.169,1.22.055,1.247Zm2.526-2.613-.169-1.362c-3.868-19.369-30.927-18.267-33.19,1.362l-.055,1.389.055,1.366c2.271,19.617,29.266,20.743,33.19,1.389l.169-1.389.055-1.366Z"
        fill="#5a5c5e" transform="translate(47.539 10.605)"/>
      <path d="M118.668,56V72.584l.937,1.22.85,1.275.74,1.366V56Z" fill="#5a5c5e"
            transform="translate(70.621 1.661)"/>
      <path
        d="M172.663,76.986l-.169,1.247c-3.4,16.284-26.181,15.277-28.164-1.247l-.027-1.224.027-1.247.169-1.22a14.214,14.214,0,0,1,12.778-11.7l1.22-.059,1.247.059a14.242,14.242,0,0,1,12.494,10.476l.256,1.22.169,1.22.055,1.247Zm2.526-2.613-.169-1.362c-3.88-19.369-30.931-18.271-33.19,1.362l-.055,1.389.055,1.366c2.275,19.625,29.255,20.743,33.19,1.389l.169-1.389.055-1.366Z"
        fill="#5a5c5e" transform="translate(138.446 10.604)"/>
      <path
        d="M171.322,68.234l.937-1.023.882-1.078.567,1.251.453,1.3a16.715,16.715,0,0,1-9.622,20.444l-1.279.453-1.334.37a16.774,16.774,0,0,1-19.192-9.822l-.453-1.307-.4-1.306H144.5l.4,1.165.508,1.165a14.185,14.185,0,0,0,15.615,7.721l1.22-.311,1.2-.4a14.211,14.211,0,0,0,8.63-16.237l-.315-1.22Z"
        fill="#5a5c5e" transform="translate(138.763 31.389)"/>
      <path
        d="M94.608,59.042l27.311,15.9V85.3L102.274,73.067v2.924l19.645,12.463V98.843L102.274,85.585v2.924l19.645,13.486v10.361L94.608,92.515Z"
        fill="#0e4293" transform="translate(0 10.575)"/>
      <path
        d="M102.06,74.94l27.311-15.9v9.283L109.7,80.532v3.1l19.672-12.494v21.38L102.06,112.356V101.995L121.7,88.509V85.585L102.06,98.843Z"
        fill="#ff4419" transform="translate(21.874 10.575)"/>
      <path d="M134.093,62.6l15.517,5.5-27.393-12.66L94.827,68.1l15.434-5.5.008,0a34.8,34.8,0,0,1,23.82-.012"
            fill="#5a5c5e" transform="translate(0.643 0)"/>
    </g>
  </svg>
</template>

<script>
const HEIGHT = 67.493;
const WIDTH = 257.297;
export default {
  name: 'ESLogo',
  props: {
    ratio: {
      type: Number,
      required: false,
      default: 1,
    },
  },
  computed: {
    height() {
      return this.ratio * HEIGHT;
    },
    width() {
      return this.ratio * WIDTH;
    },
  },
};
</script>
